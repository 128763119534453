import React, { useState } from 'react'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function NewGroup() {

    const [id, setId] = useState('')

    const navigate = useNavigate()

    const handleClick = (e) => {
        e.preventDefault()
        createGroup()
    }

    const createGroup = async (e) => {
        try {
            const response = await axios.post(`https://backend.sales-master-ai.net/v1/group/post/`, {
                group_id: id,
                users: localStorage.getItem('username'),
                group_creator: localStorage.getItem('username'),
                add_id: 'None'
            },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `token ${localStorage.getItem('token')}`,
                    },
                }
            );
            if (response.status === 200) {
                if(response['data']['error'] === 'Already exists group with id') toast.warn('この名前のグループはすでに存在します。')
                else navigate('/groups')
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div style={{ display: 'block' }}>
            <ToastContainer />
            <div className='textbox'>
                <h2 style={{ textAlign: 'center' }}>グループ新規作成</h2>
                <form id='group_form'>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <label id='group_id'>グループ名:</label>
                        <input type='text' id='group_id' name='group_id' required onChange={(e) => setId(e.target.value)} />
                    </div>
                    <button id='create_button' onClick={handleClick}>グループ作成</button>
                </form>
            </div>
        </div>
    )
}
