import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import MeChatBox from '../component/MeChatBox';
import AIChatBox from '../component/AIChatBox';
import FormInput from '../component/FormInput';
import FormTextArea from '../component/FormTextArea';
import { CircleLoader, ClipLoader } from 'react-spinners';
import { FaMicrophone } from "react-icons/fa";
import { ToastContainer } from 'react-toastify';

export default function ChatDetail() {
    const params = useParams()
    const location = useLocation()

    const containerRef = useRef(null);

    const name = location.state.name;
    const contentDisabled = location.state.disabled === 'アクティブ' ? false : true;
    const [content, setContent] = useState('')
    const [data, setData] = useState([])
    const [modify, setModify] = useState(false)
    const [personalInfo, setPersonalInfo] = useState({})
    const [btnTitle, setBtnTitle] = useState('AIの属性を確認')
    const [disabled, setDisabled] = useState(contentDisabled)
    const [isClicked, setIsClicked] = useState(false)

    const [isListening, setIsListening] = useState(false);
    const [transcript, setTranscript] = useState('');
    const [recognition, setRecognition] = useState(null);
    const [feedback, setFeedback] = useState(false)

    const mediaRecorderRef = useRef(null)
    const audioChunksRef = useRef([])
    const [audioUrl, setAudioUrl] = useState('');
    const [formData, setFormData] = useState(null)
    const [audioBase, setAudioBase] = useState('')

    const audioRef = useRef(null);

    const product_id = params.product_id

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.load(); // Load the new audio source
        }
    }, [audioBase]);

    useEffect(() => {

        if (!('webkitSpeechRecognition' in window)) {
            alert('Your browser does not support speech recognition. Please use Chrome or another supported browser.');
            return;
        }

        const speechRecognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
        speechRecognition.continuous = true;
        speechRecognition.interimResults = true;
        speechRecognition.lang = 'ja-JP';

        speechRecognition.onresult = (event) => {
            let currentTranscript = '';
            for (let i = event.resultIndex; i < event.results.length; i++) {
                if (event.results[i].isFinal) {
                    currentTranscript += event.results[i][0].transcript + '';
                }
            }
            setTranscript((prevTranscript) => prevTranscript + currentTranscript);
        };

        speechRecognition.onerror = (event) => {
            console.error('Speech recognition error detected: ' + event.error);
        };

        setRecognition(speechRecognition);
    }, []);

    useEffect(() => {
        getAudio()
    })

    const audioBufferToWav = (buffer) => {
        const numChannels = buffer.numberOfChannels;
        const sampleRate = buffer.sampleRate;
        const format = numChannels === 1 ? 1 : 2; // PCM format for mono or stereo
        const byteRate = sampleRate * numChannels * 2; // 16-bit audio
        const wavLength = 44 + buffer.length * numChannels * 2; // Header + data length

        const wavBuffer = new ArrayBuffer(wavLength);
        const view = new DataView(wavBuffer);

        // Write WAV header
        let offset = 0;
        const writeString = (str) => {
            for (let i = 0; i < str.length; i++) {
                view.setUint8(offset + i, str.charCodeAt(i));
            }
            offset += str.length;
        };

        writeString('RIFF');
        view.setUint32(offset, wavLength - 8, true); // Chunk size
        offset += 4;
        writeString('WAVE');
        writeString('fmt ');
        view.setUint32(offset, 16, true); // Subchunk1Size
        offset += 4;
        view.setUint16(offset, format, true); // AudioFormat
        offset += 2;
        view.setUint16(offset, numChannels, true); // NumChannels
        offset += 2;
        view.setUint32(offset, sampleRate, true); // SampleRate
        offset += 4;
        view.setUint32(offset, byteRate, true); // ByteRate
        offset += 4;
        view.setUint16(offset, numChannels * 2, true); // BlockAlign
        offset += 2;
        view.setUint16(offset, 16, true); // BitsPerSample
        offset += 2;
        writeString('data');
        view.setUint32(offset, buffer.length * numChannels * 2, true); // Subchunk2Size
        offset += 4;

        // Write audio data
        for (let channel = 0; channel < numChannels; channel++) {
            const channelData = buffer.getChannelData(channel);
            for (let i = 0; i < channelData.length; i++) {
                view.setInt16(offset, channelData[i] * 0x7FFF, true); // Convert to 16-bit PCM
                offset += 2;
            }
        }

        return new Blob([wavBuffer], { type: 'audio/wav' });
    };

    const mergeAudioFiles = async (base64Audio1, base64Audio2) => {
        // Convert base64 to Blob
        const audioBlob1 = await fetch(`${base64Audio1}`).then(res => res.blob());
        const audioBlob2 = await fetch(`${base64Audio2}`).then(res => res.blob());

        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const [buffer1, buffer2] = await Promise.all([
            audioContext.decodeAudioData(await audioBlob1.arrayBuffer()),
            audioContext.decodeAudioData(await audioBlob2.arrayBuffer())
        ]);

        // Create a new buffer to hold the merged audio
        const mergedBuffer = audioContext.createBuffer(
            buffer1.numberOfChannels,
            Math.max(buffer1.length, buffer2.length + buffer1.length),
            buffer1.sampleRate
        );

        // Copy audio data to the new buffer
        for (let channel = 0; channel < buffer1.numberOfChannels; channel++) {
            const mergedData = mergedBuffer.getChannelData(channel);
            mergedData.set(buffer1.getChannelData(channel), 0);
            mergedData.set(buffer2.getChannelData(channel), buffer1.length);
        }

        // Create a new audio file from the merged buffer
        const wavData = audioBufferToWav(mergedBuffer);
        const reader = new FileReader();
        reader.readAsDataURL(wavData);
        return new Promise((resolve) => {
            reader.onloadend = () => {
                const base64String = reader.result; // Get base64 part
                resolve(base64String);
            };
        });
    };

    const startListening = async () => {
        if (recognition && recognition.state === 'running') {
            recognition.stop();
        }
        if (recognition) {
            try {
                recognition.start();
                setIsListening(true);
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                mediaRecorderRef.current = new MediaRecorder(stream);
                mediaRecorderRef.current.ondataavailable = (event) => {
                    audioChunksRef.current.push(event.data);
                };
                mediaRecorderRef.current.start();
            }
            catch {
                setIsListening(false);
            }
        }
    };

    const stopListening = () => {
        if (recognition) {
            recognition.stop();
            setIsListening(false);
            mediaRecorderRef.current.stop();
            mediaRecorderRef.current.onstop = async () => {
                const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
                await handleBlobToBase64(audioBlob)
                audioChunksRef.current = [];
            };
        }
    };

    const getChat = async (e) => {
        try {
            const response = await axios.get(`https://backend.sales-master-ai.net/v1/chatroom/?uuid=${product_id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `token ${localStorage.getItem('token')}`,
                }
            },
            );
            if (response.status === 200) {
                setFeedback(false)
                setData(response['data']['chat'])
                setPersonalInfo(response['data']['personalInfo'][0])
            }
        } catch (error) {
            console.log(error)
        }
    }

    const postInfo = async (good, bad, total, mark) => {
        try {
            const response = await axios.post(`https://backend.sales-master-ai.net/v1/confirminfo/`, {
                uuid: product_id,
                good_point: good,
                bad_point: bad,
                total_mark: total,
                mark: mark
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `token ${localStorage.getItem('token')}`,
                }
            },
            );
            if (response.status === 200) {
            }
        } catch (error) {
            console.log(error)
        }
    }

    const sendTime = async (time) => {
        try {
            const response = await axios.post(`https://backend.sales-master-ai.net/v1/confirminfo/posttime/?uuid=${product_id}`, {
                spent_time: time,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `token ${localStorage.getItem('token')}`,
                }
            },
            );
            if (response.status === 200) {
                // toast.success('成功！')
            }
        } catch (error) {
            console.log(error)
        }
    }

    const uploadAudio = async (data) => {
        try {
            const response = await axios.post(`https://backend.sales-master-ai.net/v1/uploadAudio/`,
                {
                    'audio_file': data,
                    'uuid': product_id
                }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
            );
        } catch (error) {
            console.log(error)
        }
    }

    const getAudio = async (data) => {
        try {
            const response = await axios.get(`https://backend.sales-master-ai.net/v1/getAudio/?uuid=${product_id}`,
            );
            if (response.status === 200) {
                setAudioBase(response['data'])
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getOutputAudio = async (output, gender) => {
        try {
            const response = await axios.post(`https://backend.sales-master-ai.net/v1/chatroom/audio`, {
                'output': output,
                'gender': gender
            },
                {
                    responseType: 'arraybuffer',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `token ${localStorage.getItem('token')}`,
                    }
                },
            );
            if (response.status === 200) {
                const audioBlob = new Blob([response['data']], { type: 'audio/wav' })
                const audiourl = window.URL.createObjectURL(audioBlob)
                const audio = new Audio(audiourl)
                audio.play()
                await handleBlobToBase64(audioBlob)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleClick = async (e) => {
        if (transcript !== '' && isListening === false) {
            setIsClicked(true)
            setDisabled(true)
            e.preventDefault()
            try {
                const response = await axios.post(`https://backend.sales-master-ai.net/v1/chatroom/`, {
                    me_chat: transcript,
                    uuid: product_id,
                },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `token ${localStorage.getItem('token')}`,
                        }
                    },
                );
                if (response.status === 200) {
                    setModify(!modify)
                    setContent('')
                    setTranscript('')
                    setDisabled(false)
                    setIsClicked(false)
                    getOutputAudio(response['data']['status'], response['data']['gender'])
                }
            } catch (error) {
                console.log(error)
            }
        }
    }
    const blobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64data = reader.result;
                resolve(base64data);
            };
            reader.onerror = reject;
        });
    }

    const handleBlobToBase64 = async (blob) => {
        const base64data = await blobToBase64(blob);
        let mergedBuffer = ''
        if (audioBase === '') await uploadAudio(base64data)
        else {
            mergedBuffer = await mergeAudioFiles(audioBase, base64data);
            await uploadAudio(mergedBuffer)
            setAudioBase(mergedBuffer)
        }

    };

    const handleFeedback = async (e) => {
        e.preventDefault()
        setFeedback(!feedback)
        setDisabled(true)
        try {
            const response = await axios.post(`https://backend.sales-master-ai.net/v1/chatroom/`, {
                me_chat: 'フィードバック',
                uuid: product_id
            },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `token ${localStorage.getItem('token')}`,
                    }
                }
            );
            if (response.status === 200) {
                setModify(!modify)
                setDisabled(true)
                setContent('')
                if (response['data']['me_chat'] === 'フィードバック') {
                    const total = response['data']['ai_chat']
                    const goodpoint = total.indexOf('良かった点：')
                    const badpoint = total.indexOf('悪かった点：')
                    const evalpoint = total.indexOf('総評：')
                    const markpoint = total.indexOf('点数：')
                    const array_good = total.slice(goodpoint, badpoint).split('・')
                    const array_bad = total.slice(badpoint, evalpoint).split('・')
                    const array_total = total.slice(evalpoint, markpoint).slice(3).trim()
                    const array_mark = total.slice(markpoint + 3, markpoint + 7).trim()
                    postInfo(array_good.join(), array_bad.join(), array_total.toString(), array_mark.toString())
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleAttribute = () => {
        btnTitle === 'AIの属性を確認' ? setBtnTitle('チャットへ戻る') : setBtnTitle('AIの属性を確認')
    }

    useEffect(() => {
        const start = Date.now()
        getChat()
        return () => {
            const timespent = Math.floor((Date.now() - start) / 1000);
            sendTime(timespent)
        }
    }, [modify])

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    }, [data])

    return (
        <div>
            <h2>
                <Link to="/chat" style={{ textDecoration: 'none', color: '#1a1a1a' }}>営業AI一覧</Link>
                / チャット
            </h2>
            <div className='chat-container'>
                <ToastContainer />
                <div className='chat-header'>
                    <h1 className='user-name'>{name}</h1>
                    <audio ref={audioRef} controls>
                        <source src={audioBase} type="audio/wav" />
                        Your browser does not support the audio element.
                    </audio>
                    <div className='chat-header-right'>
                        <button id='feedback' onClick={handleFeedback} disabled={disabled}>フィードバック</button>
                        <button id='toggle-attributes' onClick={handleAttribute}>{btnTitle}</button>
                    </div>
                </div>
                {
                    btnTitle === 'AIの属性を確認' ? <div className='chat-log' id='chat-log' ref={containerRef}>
                        {
                            data.length === 0 ? null : feedback === false ?
                                data.map((item, index) => {
                                    return <div key={index}><MeChatBox content={item['me_chat']} />
                                        <AIChatBox content={item['ai_chat']} /></div>
                                }) : <CircleLoader />
                        }
                    </div> :
                        <div className='chat-log' id='chat-log'>
                            <form id='generate-form'>
                                <FormInput type="text" name="名前" title="name" value={personalInfo["name"]} disabled />
                                <FormInput type="number" name="年齢" title="age" value={personalInfo["age"]} disabled />
                                <FormInput type="text" name="性別" title="gender" value={personalInfo["gender"]} disabled />
                                <FormInput type="text" name="相手との関係性" title="relation" value={personalInfo["relation"]} disabled />
                                {personalInfo.type === 'toB' ? <FormInput type="text" name="役職" title="post" value={personalInfo["post"]} disabled /> : null}
                                {personalInfo.type === 'toB' ? <FormInput type="text" name="会社規模" title="company" value={personalInfo["company"]} disabled /> : null}
                                {personalInfo.type === 'toB' ? <FormInput type="text" name="事業内容" title="business" value={personalInfo["business"]} disabled /> : null}
                                <FormInput type="text" name="職業" title="profession" value={personalInfo["profession"]} disabled />
                                <FormInput type="text" name="年収" title="earning" value={personalInfo["earning"]} disabled />
                                <FormInput type="text" name="口調" title="tone" value={personalInfo["tone"]} disabled />
                                <FormInput type="text" name="性格" title="personality" value={personalInfo["personality"]} level={personalInfo['level']} disabled />
                                <FormTextArea type="text" name="背景" title="background" value={personalInfo["background"]} disabled />
                            </form>
                        </div>
                }

                <div className='chat-input'>
                    <div id='chat-form'>
                        <textarea name='message' id='msg' required rows='3' placeholder='メッセージを入力してください...' value={transcript} onChange={(e) => setTranscript(e.target.value)} disabled={disabled} style={{ paddingRight: '50px' }}></textarea>
                        <button style={{ position: 'absolute', right: '70px', bottom: '10px', background: 'none', border: 'none', color: 'black' }} onClick={isListening ? stopListening : startListening}>
                            {
                                isListening === true ? <FaMicrophone style={{ fontSize: '30px', color: 'red' }} /> : <FaMicrophone style={{ fontSize: '30px' }} />
                            }
                        </button>
                        <button onClick={handleClick} disabled={disabled}>
                            {
                                isClicked === true ? <ClipLoader color='white' size={15} /> : '送信'
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}