import React from 'react'


export default function UserTableComp(props) {

    return (
        <tr>
            <td>{props.name}</td>
            <td>{props.id}</td>
            <td>{props.password}</td>
            <td>{props.registerDate}</td>
            <td>{props.tokens}</td>
            <td>{props.availableTokens}</td>
            <td>{props.plan}</td>
            <td>{
                props.status === 'use' ? <button className='tab-button' style={{ backgroundColor: '#EDA450', padding: '0px' }}>使用中</button> :
                    props.status === 'stop' ?
                        <button className='tab-button' style={{ backgroundColor: '#808080', padding: '0px' }}>使用停止</button>
                        : null

            }
            </td>
        </tr>
    )
}
