import React, { useState } from 'react'

export default function FormInput(props) {
    const level = ['1 (極めて弱い)', '2 ( 弱い)', '3 (平均的)', '4 (強い)', '5 (極めて強い)']
    return (
        <div className='form-group'>
            <label for={props.title} style={{ width: '150px' }}>{props.name}:</label>
            {
                props.title === 'personality' ? <>
                    <input type={props.type} id={props.title} name={props.title} value={props.value} required onChange={props.handleChange} disabled={props.disabled} style={{ width: '50%' }}></input>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <label style={{ marginLeft: '10px', marginBottom: '0px' }}>性格レベル:</label>
                        <div>
                            <select style={{ height: '30px' }} onChange={props.handleLevel} disabled={props.disabled}>
                                {
                                    level.map((item, index) => (
                                        <option value={parseInt(item.slice(0, 1))}>
                                            {props.disabled === true ? level[props.level - 1] : item}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </> :
                    <input type={props.type} id={props.title} name={props.title} value={props.value} required onChange={props.handleChange} disabled={props.disabled} style={{ width: '80%' }}></input>
            }
        </div>
    )
}
