import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import HighchartsWordCloud from 'highcharts/modules/wordcloud';
import axios from 'axios';
import RankComp from '../component/RankComp';
import { toast, ToastContainer } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from 'react-modal';
import { setUser } from '../reducers/userSlice';

export default function Home() {

    HighchartsWordCloud(Highcharts);

    const [users, setUsers] = useState([])
    const [tokens, setTokens] = useState(0)
    const [availableTokens, setAvailableTokens] = useState(0)
    const [data, setData] = useState([])
    const [groupScore, setGroupScore] = useState([])
    const [groupUuid, setGroupUuid] = useState([])
    const [groupName, setGroupName] = useState([])
    const [word, setWord] = useState([])
    const [group, setGroup] = useState([])
    const [total, setTotal] = useState(0)
    const [spentTime, setSpentTime] = useState([])
    const [display, setDisplay] = useState('mark')
    const [period, setPeriod] = useState('month')
    const [groupItem, setGroupItem] = useState([])
    const [userIdArray, setUserIdArray] = useState([])
    const [selectedItem, setSelectedItem] = useState('')
    const [monthUser, setMonthUser] = useState(new Array(12).fill(0))
    const [monthRegister, setMonthRegister] = useState(new Array(12).fill(0))
    const [monthManager, setMonthManager] = useState(new Array(12).fill(0))
    const [monthManagerRegister, setMonthManagerRegister] = useState(new Array(12).fill(0))
    const [monthUserRegister, setMonthUserRegister] = useState(new Array(12).fill(0))
    const [monthAddToken, setMonthAddToken] = useState(new Array(12).fill(0))
    const [monthEntry, setMonthEntry] = useState(new Array(12).fill(0))
    const [monthStandard, setMonthStandard] = useState(new Array(12).fill(0))
    const [monthPremium, setMonthPremium] = useState(new Array(12).fill(0))
    const [feature, setFeature] = useState('register')

    const location = useLocation()
    const detailId = location.state ? location.state.userId : "none"
    const navigate = useNavigate()

    const [date, setDate] = useState(new Date());

    const [startDate, setStartDate] = useState(new Date());
    const [isOpen, setIsOpen] = useState(false);
    const [daysInMonth, setDaysInMonth] = useState(new Date().getDate());
    const [selectedWeek, setSelectedWeek] = useState(null);
    const [month, setMonth] = useState(new Date().getMonth() + 1)
    const [year, setYear] = useState(new Date().getFullYear())
    const [currentGroup, setCurrentGroup] = useState('none')

    const [series, setSeries] = useState([])

    const handleButtonClick = () => {
        setIsOpen(!isOpen);
        console.log(date)
    };

    const handleMonthChange = (date) => {
        setStartDate(date);
        const year = date.getFullYear();
        const month = date.getMonth();
        const days = new Date(year, month + 1, 0).getDate();
        setDaysInMonth(days);
        setYear(year)
        setMonth(month + 1)
        setIsOpen(false); // Close the calendar after selection
        console.log(month)
        if (currentGroup !== 'none') {
            setSeries([])
            userIdArray.map((item, index) => {
                const start = period === 'month' ? month + 1 : selectedWeek['start'].getDate()
                let temp = getSeries(item, period, start, currentGroup, year)
                if (temp.length > 0) {
                    setSeries(prevArray => [...prevArray, { name: item, id: item, data: temp }])
                }
            })
        }
    };

    const handleWeekChange = (date) => {
        setStartDate(date);
        const weekStart = new Date(date);
        weekStart.setDate(date.getDate() - date.getDay()); // Set to the first day of the week (Sunday)
        const weekEnd = new Date(weekStart);
        weekEnd.setDate(weekStart.getDate() + 6); // Set to the last day of the week (Saturday)

        setSelectedWeek(weekStart.getDate());
        setMonth(weekStart.getMonth() + 1);

        console.log("Selected week:", { start: weekStart.getDate(), end: weekEnd });
        setIsOpen(false);
        if (currentGroup !== 'none') {
            setSeries([])
            userIdArray.map((item, index) => {
                const start = period === 'month' ? month : weekStart.getDate()
                let temp = getSeries(item, period, start, currentGroup, year)
                if (temp.length > 0) {
                    setSeries(prevArray => [...prevArray, { name: item, id: item, data: temp }])
                }
            })
        } // Close the modal after selection
    };

    const formatDate = (date) => {
        return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
    };

    const closeModal = () => {
        setIsOpen(false);
    };


    const wordOptions = {
        chart: {
            type: "wordcloud"
        },
        series: [{
            data: word,
            name: '使用回数',
            className: 'wordcloud-series'
        }],
        title: {
            text: '単語クラウド',
            align: 'left',
            style: {
                color: '#333',
                fontFamily: 'Noto Sans JP'
            }
        },
        subtitle: {
            text: '営業時の単語',
            align: 'left',
        },
        tooltip: {
            headerFormat: '<span style="font-size: 25px"><b>{point.key}</b></span><br>'
        }
    };

    const feedbackOptions = {
        chart: {
            type: 'line'
        },
        title: {
            text: '直近の点数',
            align: 'left'
        },
        xAxis: {
            title: {
                text: ''
            },
            tickInterval: 1,
            min: 0.5,
            max: data.length + 0.5
        },
        yAxis: {
            title: {
                text: '点数',
            },
            min: 0,
            max: Math.max(...data) + 1.5
        },
        tooltip: {
            pointFormat: '<span style="color:{point.color};font-size:20px;">\u25CF</span> {series.name}: <b>{point.y}</b>'
        },
        series: [
            {
                name: '点数',
                pointStart: 1,
                data: data
            }
        ]
    };

    const timeOptions = {
        chart: {
            type: 'line'
        },
        title: {
            text: '直近の時間',
            align: 'left'
        },
        xAxis: {
            title: {
                text: ''
            },
            tickInterval: 1,
            min: 0.5,
            max: spentTime.length + 0.5
        },
        yAxis: {
            title: {
                text: '時間(分)',
            },
            min: 0,
            max: Math.max(...spentTime) + 1.5
        },
        tooltip: {
            pointFormat: '<span style="color:{point.color};font-size:20px;">\u25CF</span> {series.name}: <b>{point.y}</b>'
        },
        series: [
            {
                name: '時間',
                pointStart: 1,
                data: spentTime
            }
        ]
    };

    const userOptions = {
        chart: {
            type: 'column'
        },
        title: {
            align: 'left',
            text: 'ユーザー数'
        },
        xAxis: {
            type: 'category'
        },
        yAxis: {
            tickInterval: 1,
            title: {
                text: 'ユーザー数',
            }
        },

        tooltip: {
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: ' +
                '<b>{point.y}<br/>'
        },

        series: [
            {
                name: '月',
                colorByPoint: true,
                data: [
                    {
                        name: '1月',
                        y: monthUser[0],
                    },
                    {
                        name: '2月',
                        y: monthUser[1],
                    },
                    {
                        name: '3月',
                        y: monthUser[2],
                    },
                    {
                        name: '4月',
                        y: monthUser[3],
                    },
                    {
                        name: '5月',
                        y: monthUser[4],
                    },
                    {
                        name: '6月',
                        y: monthUser[5],
                    },
                    {
                        name: '7月',
                        y: monthUser[6],
                    },
                    {
                        name: '8月',
                        y: monthUser[7],
                    },
                    {
                        name: '9月',
                        y: monthUser[8],
                    },
                    {
                        name: '10月',
                        y: monthUser[9],
                    },
                    {
                        name: '11月',
                        y: monthUser[10],
                    },
                    {
                        name: '12月',
                        y: monthUser[11],
                    }
                ]
            }
        ],
    }

    const userRegisterOptions = {
        chart: {
            type: 'column'
        },
        title: {
            text: '新規登録',
            align: 'left'
        },
        xAxis: {
            categories: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
            crosshair: true,
            accessibility: {
                description: '月'
            }
        },
        yAxis: {
            min: 0,
            tickInterval: 1,
            title: {
                text: '登録者数'
            }
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        series: [
            {
                name: 'ユーザー',
                data: monthRegister
            },
        ]
    };

    const monthGroupOptions = {
        chart: {
            type: 'scatter',
            zooming: {
                type: 'xy'
            }
        },
        title: {
            text: '月別点数',
            align: 'left'
        },
        xAxis: {
            title: {
                text: `${month}月`
            },
            categories: Array.from({ length: daysInMonth }, (_, i) => i + 1),
        },
        yAxis: {
            title: {
                text: '点数'
            },
        },
        legend: {
            enabled: true
        },
        plotOptions: {
            scatter: {
                marker: {
                    radius: 2.5,
                    symbol: 'circle',
                    states: {
                        hover: {
                            enabled: true,
                            lineColor: 'rgb(100,100,100)'
                        }
                    }
                },
                states: {
                    hover: {
                        marker: {
                            enabled: false
                        }
                    }
                },
                jitter: {
                    x: 0.005
                }
            }
        },
        tooltip: {
            pointFormat: '点数: {point.y}'
        },
        series: series
    }

    const weekGroupOptions = {
        chart: {
            type: 'scatter',
            zooming: {
                type: 'xy'
            }
        },
        title: {
            text: '週別点数',
            align: 'left'
        },
        xAxis: {
            title: {
                text: `${month}月`
            },
            categories: Array.from({ length: daysInMonth }, (_, i) => i + 1),
        },
        yAxis: {
            title: {
                text: '点数'
            },
        },
        legend: {
            enabled: true
        },
        plotOptions: {
            scatter: {
                marker: {
                    radius: 2.5,
                    symbol: 'circle',
                    states: {
                        hover: {
                            enabled: true,
                            lineColor: 'rgb(100,100,100)'
                        }
                    }
                },
                states: {
                    hover: {
                        marker: {
                            enabled: false
                        }
                    }
                },
                jitter: {
                    x: 0.005
                }
            }
        },
        tooltip: {
            pointFormat: `点数: {point.y}`
        },
        series: series
    }

    const usageOptions = {
        chart: {
            type: 'column'
        },
        title: {
            text: '使用中',
            align: 'left'
        },
        xAxis: {
            categories: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
            crosshair: true,
            accessibility: {
                description: '月'
            }
        },
        yAxis: {
            min: 0,
            tickInterval: 1,
            title: {
                text: 'ユーザー数'
            }
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        series: [
            {
                name: 'マネージャー',
                data: monthManager
            },
            {
                name: 'ユーザー',
                data: monthUser
            }
        ]
    };

    const registerOptions = {
        chart: {
            type: 'column'
        },
        title: {
            text: '新規登録',
            align: 'left'
        },
        xAxis: {
            categories: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
            crosshair: true,
            accessibility: {
                description: '月'
            }
        },
        yAxis: {
            min: 0,
            tickInterval: 1,
            title: {
                text: 'ユーザー数'
            }
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        series: [
            {
                name: 'マネージャー',
                data: monthManagerRegister
            },
            {
                name: 'ユーザー',
                data: monthRegister
            }
        ]
    };

    const monthRegisterOptions = {
        chart: {
            type: 'column'
        },
        title: {
            text: '新規登録',
            align: 'left'
        },
        xAxis: {
            categories: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
            crosshair: true,
            accessibility: {
                description: '月'
            }
        },
        yAxis: {
            min: 0,
            tickInterval: 1,
            title: {
                text: '数'
            }
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        series: [
            {
                name: 'エントリー',
                data: monthEntry
            }, {
                name: 'スタンダード',
                data: monthStandard
            }, {
                name: 'プレミアム',
                data: monthPremium
            },
        ]
    };

    const monthTokenAddOptions = {
        chart: {
            type: 'column'
        },
        title: {
            text: 'トークン追加回収',
            align: 'left'
        },
        xAxis: {
            categories: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
            crosshair: true,
            accessibility: {
                description: '月'
            }
        },
        yAxis: {
            min: 0,
            tickInterval: 1,
            title: {
                text: '回収'
            }
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        series: [
            {
                name: 'トークン追加',
                data: monthAddToken
            },
        ]
    };

    const getInfo = async (e) => {
        try {
            const response = await axios.get(`https://backend.sales-master-ai.net/api/account/info/?token=${localStorage.getItem('token')}&&admin=${false}`, {
            },
            );
            if (response.status === 200) {
                setTokens(response['data']['amount'])
                setAvailableTokens(response['data']['data']['available_tokens'])
                setGroup(response['data']['data']['groups'].split(','))
                setGroup(prevArray => prevArray.filter((_, index) => index !== 0));
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getDetailInfo = async (e) => {
        setData([])
        try {
            const response = await axios.get(`https://backend.sales-master-ai.net/v1/confirminfo/?detailId=${detailId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `token ${localStorage.getItem('token')}`,
                }
            },
            );
            if (response.status === 200) {
                const total = response['data']
                setData([])
                setWord([])
                setSpentTime([])
                let tempWord = []
                let total_length = 0
                for (let i = 0; i < total.length; i++) {
                    if (total[i]['active_mark'] !== "") total_length++
                }
                for (let i = 0; i < total.length; i++) {
                    setSpentTime(prevArray => [...prevArray, total[i]['spent_time']])
                    if (total[i]['active_mark'] !== "") {
                        let length = 0
                        const temp = total[i]['active_mark'].match(/\d+/g).map(Number)[0] + total[i]['active_mark'].match(/\d+/g).map(Number)[1] + total[i]['active_mark'].match(/\d+/g).map(Number)[2] + total[i]['active_mark'].match(/\d+/g).map(Number)[3] + total[i]['active_mark'].match(/\d+/g).map(Number)[4]
                        setData(prevArray => [...prevArray, temp])
                        const endActive = total[i]['active_mark'].indexOf('総合コミュニケーション:') + 14
                        const words = total[i]['active_mark'].slice(endActive).replace(/\n/g, '').split(',')
                        for (let i = 0; i < words.length; i++) {
                            if (words[i] !== 'です' || words[i] !== 'ます' || words[i] !== 'ありがとうございます') {
                                if (tempWord.some(item => item.name === words[i].split(':')[0]) === false) {
                                    tempWord.push({ name: words[i].split(':')[0], weight: parseInt(words[i].split(':')[1]) })
                                    length++;
                                    if (length > 40 / total_length) break
                                }
                            }
                        }
                    }
                }
                setWord(tempWord)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getMonthRegister = async (e) => {
        try {
            const response = await axios.get(`https://backend.sales-master-ai.net/api/month-info/?managerId=${localStorage.getItem('username')}`, {
            },
            );
            if (response.status === 200) {
                const month_register = new Array(12).fill(0)
                response['data'].map((item, index) => {
                    month_register[item['month'] - 1] = item['register']
                })
                setMonthRegister(month_register)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getUserInfo = async (e) => {
        try {
            const response = await axios.get(`https://backend.sales-master-ai.net/api/users?manager=${localStorage.getItem('username')}`, {
            },
            );
            if (response.status === 200) {
                const month_user = new Array(12).fill(0)
                let total_user = 0
                setGroupItem([])
                setUserIdArray([])
                response['data']['data'].map((item, index) => {
                    const date = new Date(item['created_at'])
                    if (userIdArray.includes(item['userId']) === false) setUserIdArray(prevArray => [...prevArray, item['userId']])
                    if (item['status'] !== 'unAuth') {
                        month_user[date.getMonth()] = month_user[date.getMonth()] + 1;
                        total_user++
                    }
                })
                setMonthUser(month_user)
                setTotal(total_user)
                response['data']['group'].map((item, index) => {
                    setGroupItem(prevArray => [...prevArray, item['group_id']])
                })
                let arrayIndividual = []
                response['data']['personal'].map((personal, index) => {
                    let individual = {}
                    if (personal['active_mark'] !== '') {
                        individual = {
                            'year': parseInt(personal['recent_time'].slice(0, 4)),
                            'month': parseInt(personal['recent_time'].slice(5, 7).replace('0', '')),
                            'day': parseInt(personal['recent_time'].slice(8, 10).replace('0', '')),
                            'active_mark': personal['active_mark'].match(/\d+/g).map(Number)[0] + personal['active_mark'].match(/\d+/g).map(Number)[1] + personal['active_mark'].match(/\d+/g).map(Number)[2] + personal['active_mark'].match(/\d+/g).map(Number)[3] + personal['active_mark'].match(/\d+/g).map(Number)[4],
                            'user': personal['user'],
                            'group': response['data']['data'].find(item => item.userId === personal['user'])['groups']
                        }
                        arrayIndividual.push(individual)
                    }
                })
                setUsers(arrayIndividual)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getMonthlyUser = async (e) => {
        try {
            const response = await axios.get(`https://backend.sales-master-ai.net/api/monthly/`, {
            },
            );
            if (response.status === 200) {
                const month_user = new Array(12).fill(0)
                const month_register = new Array(12).fill(0)
                const month_manager = new Array(12).fill(0)
                const month_manger_register = new Array(12).fill(0)
                response['data'].map((item, index) => {
                    month_user[item['month'] - 1] = item['usage'];
                    month_register[item['month'] - 1] = item['register'];
                    month_manager[item['month'] - 1] = item['manager_usage'];
                    month_manger_register[item['month'] - 1] = item['manager_register']
                })
                setMonthUser(month_user)
                setMonthRegister(month_register)
                setMonthManager(month_manager)
                setMonthManagerRegister(month_manger_register)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getManagerInfo = async (e) => {
        try {
            const response = await axios.get(`https://backend.sales-master-ai.net/api/manager/`, {
            },
            );
            if (response.status === 200) {
                setUsers(response['data'])
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getAIPersonal = async () => {
        setData([])
        try {
            const response = await axios.get(`https://backend.sales-master-ai.net/v1/confirminfo/`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `token ${localStorage.getItem('token')}`,
                }
            },
            );
            if (response.status === 200) {
                const total = response['data']
                setData([])
                setWord([])
                setSpentTime([])
                let total_length = 0
                let tempWord = []
                for (let i = 0; i < total.length; i++) {
                    if (total[i]['active_mark'] !== "") total_length++
                }
                for (let i = 0; i < total.length; i++) {
                    setSpentTime(prevArray => [...prevArray, Math.round(total[i]['spent_time'] / 60)])
                    if (total[i]['active_mark'] !== "") {
                        let length = 0
                        const temp = total[i]['active_mark'].match(/\d+/g).map(Number)[0] + total[i]['active_mark'].match(/\d+/g).map(Number)[1] + total[i]['active_mark'].match(/\d+/g).map(Number)[2] + total[i]['active_mark'].match(/\d+/g).map(Number)[3] + total[i]['active_mark'].match(/\d+/g).map(Number)[4]
                        setData(prevArray => [...prevArray, temp])
                        const endActive = total[i]['active_mark'].indexOf('総合コミュニケーション:') + 14
                        const words = total[i]['active_mark'].slice(endActive).replace(/\n/g, '').split(',')
                        for (let i = 0; i < words.length; i++) {
                            if (tempWord.some(item => item.name === words[i].split(':')[0]) === false) {
                                tempWord.push({ name: words[i].split(':')[0], weight: parseInt(words[i].split(':')[1]) })
                                length++;
                                if (length > 40 / total_length) break
                            }
                        }
                    }
                }
                setWord(tempWord)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        setData([])
        if (localStorage.getItem('admin') === 'manager') getUserInfo()
        if (localStorage.getItem('admin') === 'manager') getMonthRegister()
        if (localStorage.getItem('admin') === 'user') getAIPersonal()
        if (localStorage.getItem('admin') === 'user') getInfo()
        if (localStorage.getItem('admin') === 'admin') getMonthlyUser()
        if (localStorage.getItem('admin') === 'admin') getManagerInfo()
        if (detailId !== 'none') getDetailInfo()
    }, [])

    const openTab = (index) => {
        if (index === 0) {
            document.getElementById("feedbacks").style.display = 'block';
            document.getElementById("words-btn").classList.remove("status-button-active");
            document.getElementById("feedbacks-btn").classList.add("status-button-active");
            document.getElementById("words").style.display = 'none';
            document.getElementById("tabButtons").style.display = 'block';
        }
        if (index === 1) {
            document.getElementById("words").style.display = 'block';
            document.getElementById("words-btn").classList.add("status-button-active");
            document.getElementById("feedbacks").style.display = 'none';
            document.getElementById("feedbacks-btn").classList.remove("status-button-active");
            document.getElementById("tabButtons").style.display = 'none';
        }
        if (index === 2) {
            document.getElementById("feedbacks").style.display = 'block';
            document.getElementById("words").style.display = 'none';
            setDisplay('mark')
        }
        if (index === 3) {
            document.getElementById("feedbacks").style.display = 'block';
            document.getElementById("words").style.display = 'none';
            setDisplay('time')
        }
        if (index === 4) {
            document.getElementById("usage-option").style.display = 'block';
            document.getElementById("register-option").style.display = 'none';
            setDisplay('mark')
        }
        if (index === 5) {
            document.getElementById("usage-option").style.display = 'none';
            document.getElementById("register-option").style.display = 'block';
            setDisplay('time')
        }
        if (index === 6) {
            document.getElementById("user-option").style.display = 'block';
            document.getElementById("register-user-option").style.display = 'none';
            setDisplay('mark')
        }
        if (index === 7) {
            document.getElementById("user-option").style.display = 'none';
            document.getElementById("register-user-option").style.display = 'block';
            setDisplay('time')
        }
        if (index === 8) {
            document.getElementById("month-option").style.display = 'block';
            document.getElementById("week-option").style.display = 'none';
            setPeriod('month')
        }
        if (index === 9) {
            document.getElementById("month-option").style.display = 'none';
            document.getElementById("week-option").style.display = 'block';
            setPeriod('week')
        }
        if (index === 10) {
            document.getElementById("month-register-option").style.display = 'block';
            document.getElementById("month-tokenAdd-option").style.display = 'none';
            setFeature('register')
        }
        if (index === 11) {
            document.getElementById("month-register-option").style.display = 'none';
            document.getElementById("month-tokenAdd-option").style.display = 'block';
            setFeature('tokenAdd')
        }
    }

    const addToken = async () => {
        try {
            const response = await axios.post(`https://backend.sales-master-ai.net/api/account/add_token/`, {
                add_token: 1000000,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `token ${localStorage.getItem('token')}`,
                }
            },
            );
            if (response.status === 200) {
                toast.success('要求成功！')
                // setIsDisabled(false)
            }
        } catch (error) {
            console.log(error)
        }

    }

    const handleGroup = async (e) => {
        if (e.target.value !== 'none') {
            try {
                const response = await axios.get(`https://backend.sales-master-ai.net/v1/group/?groupId=${e.target.value}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `token ${localStorage.getItem('token')}`,
                    }
                },
                );
                if (response.status === 200) {
                    const total = response['data']['data']
                    const total_user = response['data']['user']
                    for (let i = 0; i < total.length; i++) {
                        if (total[i]['active_mark'] !== "") {
                            const temp_score = total[i]['active_mark'].match(/\d+/g).map(Number)[0] + total[i]['active_mark'].match(/\d+/g).map(Number)[1] + total[i]['active_mark'].match(/\d+/g).map(Number)[2] + total[i]['active_mark'].match(/\d+/g).map(Number)[3] + total[i]['active_mark'].match(/\d+/g).map(Number)[4]
                            setGroupScore(prevArray => [...prevArray, temp_score])
                            setGroupUuid(prevArray => [...prevArray, total[i]['uuid']])
                            setGroupName(prevArray => [...prevArray, total_user[i]])
                        }
                    }
                    const numberWithIndices = groupScore.map((number, index) => ({ number, index }))
                    const sorted = numberWithIndices.sort((a, b) => b.number - a.number)
                    setGroupScore(sorted)
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    const getSeries = (user, date, start, group, year) => {
        let temp = []
        users.map((item, index) => {
            if (item['user'] === user && date === 'month' && item['group'].split(',').includes(group)) {
                if (parseInt(item['month']) === start && parseInt(item['year']) === year) {
                    temp.push([item['day'] - 1, item['active_mark']])
                }
            }
            if (item['user'] === user && date === 'week' && item['group'].split(',').includes(group)) {
                if (parseInt(item['day']) >= parseInt(start) && parseInt(item['day']) <= parseInt(start) + 6 && parseInt(item['year']) === year) temp.push([item['day'] - 1, item['active_mark']])
            }
        })
        return temp
    }

    const handleGroupItem = async (e) => {
        const group = e.target.value
        setCurrentGroup(group)
        setSeries([])
        if (group !== 'none') {
            userIdArray.map((item, index) => {
                const start = period === 'month' ? month : selectedWeek
                let temp = getSeries(item, period, start, group, year)
                if (temp.length > 0) {
                    setSeries(prevArray => [...prevArray, { name: item, id: item, data: temp }])
                }
            })
        }
    }

    const handleManagerItem = async (e) => {
        if (e.target.value !== 'none') {
            try {
                const response = await axios.get(`https://backend.sales-master-ai.net/api/month-info/?managerId=${e.target.value}`, {
                },
                );
                if (response.status === 200) {
                    const month_register = new Array(12).fill(0)
                    const month_entry = new Array(12).fill(0)
                    const month_standard = new Array(12).fill(0)
                    const month_premium = new Array(12).fill(0)
                    const month_addToken = new Array(12).fill(0)
                    response['data'].map((item, index) => {
                        month_register[item['month'] - 1] = item['register']
                        month_addToken[item['month'] - 1] = item['addToken']
                        month_entry[item['month'] - 1] = item['entry']
                        month_standard[item['month'] - 1] = item['standard']
                        month_premium[item['month'] - 1] = item['premium']
                    })
                    setMonthUserRegister(month_register)
                    setMonthAddToken(month_addToken)
                    setMonthEntry(month_entry)
                    setMonthStandard(month_standard)
                    setMonthPremium(month_premium)
                }
            } catch (error) {
                console.log(error)
            }
        }

    }



    return (
        <>
            {
                localStorage.getItem('admin') === "user" || detailId !== "none" ? <div className="flex-box">
                    <ToastContainer />
                    <div className="right-side">
                        <div className="tabs">
                            <button id="feedbacks-btn" className="status-button status-button-active" style={{ width: '100%' }} onClick={() => openTab(0)}>
                                フィードバック
                            </button>
                            <button id="words-btn" className="status-button" style={{ width: '100%' }} onClick={() => openTab(1)}>
                                使用単語
                            </button>
                        </div>
                        <div style={{ display: 'flex' }} id='tabButtons'>
                            <button className={`tab-button ${display === 'mark' ? 'tab-button-active' : null}`} style={{ backgroundColor: '#EDA450' }} onClick={() => openTab(2)}>点数</button>
                            <button className={`tab-button ${display === 'time' ? 'tab-button-active' : null}`} style={{ backgroundColor: '#3BA574' }} onClick={() => openTab(3)}>時間</button>
                        </div>
                        <div id="feedbacks" className="tabcontent" style={{ display: "block" }}>
                            {
                                display === 'mark' ? <div>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={feedbackOptions}
                                    />
                                </div> :
                                    <div>
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={timeOptions}
                                        />
                                    </div>
                            }
                        </div>
                        <div id="words" className="tabcontent" style={{ display: "none" }}>
                            <div>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={wordOptions}
                                />
                            </div>
                        </div>
                        {
                            detailId === "none" ?
                                <div id="tokens" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>
                                        <p className="tokens-used">使用トークン: {tokens.toLocaleString('en-US')}</p>
                                        <p className="tokens-available">※利用可能トークン: {availableTokens.toLocaleString('en-US')}</p>
                                    </div>
                                    <button className="status-button" style={{ width: '200px' }} onClick={addToken}>
                                        1,000,000トークン追加
                                    </button>
                                </div> :
                                null
                        }
                    </div>
                    {
                        detailId === "none" ? <div className="left-side">
                            <div id="ranking">
                                <h2>ランキング</h2>
                                <div id='group-select-container'>
                                    <select style={{ height: '40px', borderRadius: '5px' }} onChange={handleGroup}>
                                        <option value="none">
                                            選択してください
                                        </option>
                                        {
                                            group.map((item, index) => {
                                                return <option value={item} key={index}>
                                                    {item}
                                                </option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div className='ranking-container'>
                                    {
                                        groupScore.map((item, index) => (
                                            index < 5 ? <RankComp key={index} name={groupName[index]} score={item} url={`/feedback/${groupUuid[index]}`} /> : null
                                        ))
                                    }
                                </div>
                            </div>
                        </div> :
                            null
                    }
                </div> : localStorage.getItem('admin') === 'manager' ?
                    <div style={{ display: 'block' }}>
                        <div className='textbox' style={{ textAlign: 'center' }}>
                            <h2>ユーザー数</h2>
                            <p>{total}</p>
                            <div style={{ display: 'flex', maxWidth: '100px' }} id='tabButtons'>
                                <button className={`tab-button ${display === 'mark' ? 'tab-button-active' : null}`} style={{ backgroundColor: '#EDA450' }} onClick={() => openTab(6)}>使用</button>
                                <button className={`tab-button ${display === 'time' ? 'tab-button-active' : null}`} style={{ backgroundColor: '#3BA574' }} onClick={() => openTab(7)}>新規</button>
                            </div>
                            <div id='user-option' style={{ display: "block" }}>
                                <div>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={userOptions}
                                    />
                                </div>
                            </div>
                            <div id='register-user-option' style={{ display: "none" }}>
                                <div>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={userRegisterOptions}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='textbox-score' style={{ textAlign: 'center', marginTop: '20px' }}>
                            <div style={{ display: 'flex', maxWidth: '100px' }} id='tabButtons'>
                                <button className={`tab-button ${period === 'month' ? 'tab-button-active' : null}`} style={{ backgroundColor: '#EDA450' }} onClick={() => openTab(8)}>月</button>
                                <button className={`tab-button ${period === 'week' ? 'tab-button-active' : null}`} style={{ backgroundColor: '#3BA574' }} onClick={() => openTab(9)}>週</button>
                            </div>
                            <div id='month-option' style={{ display: "block" }}>
                                <div>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={monthGroupOptions}
                                    />
                                </div>
                            </div>
                            <div id='week-option' style={{ display: "none" }}>
                                <div>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={weekGroupOptions}
                                    />
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                {
                                    period === 'month' ? <>
                                        <label style={{ marginLeft: '10px', marginBottom: '0px', marginRight: '10px' }}>月を選択してください:</label>
                                        <div style={{ marginRight: '20px' }}>
                                            <div>
                                                <button onClick={handleButtonClick} style={{ padding: '10px' }}>カレンダーを表示</button>
                                                <Modal
                                                    isOpen={isOpen}
                                                    onRequestClose={closeModal}
                                                    contentLabel="月を選択してください"
                                                    style={{
                                                        content: {
                                                            top: '50%',
                                                            left: '50%',
                                                            right: 'auto',
                                                            bottom: 'auto',
                                                            marginRight: '-50%',
                                                            transform: 'translate(-50%, -50%)',
                                                        },
                                                    }}
                                                >
                                                    <h2>月を選択してください</h2>
                                                    <DatePicker
                                                        selected={startDate}
                                                        onChange={handleMonthChange}
                                                        dateFormat="MM/yyyy"
                                                        showMonthYearPicker
                                                        inline
                                                        calendarClassName="custom-calendar"
                                                    />
                                                </Modal>
                                            </div>
                                        </div>
                                        <label style={{ marginLeft: '10px', marginBottom: '0px', marginRight: '10px' }}>グループを選択してください:</label>
                                        <div id='group-select-container'>
                                            <select style={{ height: '40px', borderRadius: '5px' }} onChange={handleGroupItem}>
                                                <option value="none">
                                                    選択してください
                                                </option>
                                                {
                                                    groupItem.map((item, index) => {
                                                        return <option value={item} key={index}>
                                                            {item}
                                                        </option>
                                                    })
                                                }
                                            </select>
                                        </div></> :
                                        <>
                                            <label style={{ marginLeft: '10px', marginBottom: '0px', marginRight: '10px' }}>週を選択してください:</label>
                                            <div style={{ marginRight: '20px' }}>
                                                <div>
                                                    <button onClick={handleButtonClick} style={{ padding: '10px' }}>カレンダーを表示</button>
                                                    <Modal
                                                        isOpen={isOpen}
                                                        onRequestClose={closeModal}
                                                        contentLabel="週を選択してください"
                                                        style={{
                                                            content: {
                                                                top: '50%',
                                                                left: '50%',
                                                                right: 'auto',
                                                                bottom: 'auto',
                                                                marginRight: '-50%',
                                                                transform: 'translate(-50%, -50%)',
                                                            },
                                                        }}
                                                    >
                                                        <h2>週を選択してください</h2>
                                                        <DatePicker
                                                            selected={startDate}
                                                            onChange={handleWeekChange}
                                                            calendarClassName="custom-calendar"
                                                            inline
                                                            showWeekNumbers
                                                            showFullMonthYearPicker
                                                        />
                                                    </Modal>
                                                </div>
                                            </div>
                                            <label style={{ marginLeft: '10px', marginBottom: '0px', marginRight: '10px' }}>グループを選択してください:</label>
                                            <div id='group-select-container'>
                                                <select style={{ height: '40px', borderRadius: '5px' }} onChange={handleGroupItem}>
                                                    <option value="none">
                                                        選択してください
                                                    </option>
                                                    {
                                                        groupItem.map((item, index) => {
                                                            return <option value={item} key={index}>
                                                                {item}
                                                            </option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </>
                                }
                            </div>
                        </div>
                    </div> :
                    <div style={{ display: 'block' }}>
                        <div className='textbox' style={{ textAlign: 'center' }}>
                            <div style={{ display: 'flex', maxWidth: '100px' }} id='tabButtons'>
                                <button className={`tab-button ${display === 'mark' ? 'tab-button-active' : null}`} style={{ backgroundColor: '#EDA450' }} onClick={() => openTab(4)}>使用</button>
                                <button className={`tab-button ${display === 'time' ? 'tab-button-active' : null}`} style={{ backgroundColor: '#3BA574' }} onClick={() => openTab(5)}>新規</button>
                            </div>
                            <div id='usage-option' style={{ display: "block" }}>
                                <div>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={usageOptions}
                                    />
                                </div>
                            </div>
                            <div id='register-option' style={{ display: "none" }}>
                                <div>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={registerOptions}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='textbox-score' style={{ textAlign: 'center', marginTop: '20px' }}>
                            <div style={{ display: 'flex', maxWidth: '100px' }} id='tabButtons'>
                                <button className={`tab-button ${feature === 'register' ? 'tab-button-active' : null}`} style={{ backgroundColor: '#EDA450' }} onClick={() => openTab(10)}>新規</button>
                                <button className={`tab-button ${feature === 'tokenAdd' ? 'tab-button-active' : null}`} style={{ backgroundColor: '#3BA574' }} onClick={() => openTab(11)}>追加</button>
                            </div>
                            <div id='month-register-option' style={{ display: "block" }}>
                                <div>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={monthRegisterOptions}
                                    />
                                </div>
                            </div>
                            <div id='month-tokenAdd-option' style={{ display: "none" }}>
                                <div>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={monthTokenAddOptions}
                                    />
                                </div>
                            </div>
                            <div id='group-select-container'>
                                <select style={{ height: '40px', borderRadius: '5px' }} onChange={handleManagerItem}>
                                    <option value="none">
                                        選択してください
                                    </option>
                                    {
                                        users.map((item, index) => {
                                            return <option value={item['managerId']} key={index}>
                                                {item['managerId']}
                                            </option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}
