import React from "react";
import "../main/ToggleSwitch.css";

const ToggleSwitch = (props) => {
return (
	<div className="container">
	<div className="toggle-switch">
		<input type="checkbox" className="checkbox"
			name={props.label} id={props.label} onChange={props.handleToggle}/>
		<label className="label" htmlFor={props.label}>
		<span className="inner" />
		<span className="switch" />
		</label>
	</div>
	</div>
);
};

export default ToggleSwitch;
