import React, { useEffect, useState } from 'react'
import FormInput from '../component/FormInput'
import FormTextArea from '../component/FormTextArea'
import TemplateButton from '../component/TemplateButton'
import FormRadio from '../component/FormRadio'
import { useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import ToggleSwitch from '../component/ToggleSwitch'
import templateA from '../template/templateA.json'

export default function ChatNew() {

    const { role } = useSelector(state => state.user)

    const navigate = useNavigate()
    const location = useLocation()

    let sharedata = { 'relation': '見込み顧客', 'profession': 'エンジニア', 'background': '' }

    if (location?.state) {
        sharedata = location.state.data
    }

    const relationship = ['新規顧客', '見込み顧客', '既存顧客']
    const professionList = ['エンジニア', 'ジャーナリスト', 'スポーツトレーナー', 'パティシエ', '医師', '営業マン', '栄養士', '看護師', '機械技師', '気象予報士', '漁師', '教師', '銀行員', '警察官', '建築家', '検察官', '研究者', '公務員', '裁判官', '作家', '作業療法士', '司会者', '歯科医師', '自動車整備士', '受付', '塾講師', '助産師', '消防士', '図書館司書', '政治家', '設計士', '大工', '調理師', '通訳者', '土木技師', '農家', '俳優', '配管工', '販売員', '美容師', '福祉士', '編集者', '弁護士', '保育士', '翻訳者', '郵便局員', '理学療法士', '理容師', '旅行代理店', '料理人']
    const carrierList = ['au', 'docomo', 'linemo', 'NUROモバイル', 'povo', 'SoftBank', 'Ymobile', 'イオンモバイル', '楽天モバイル']

    const [btnTitle, setBtnTitle] = useState('営業相手を作る')
    const [data, setData] = useState(sharedata)
    const [level, setLevel] = useState(1)
    const [relation, setRelation] = useState('見込み顧客')
    const [profession, setProfession] = useState('エンジニア')
    const [carrier, setCarrier] = useState('au')
    const [fees, setFees] = useState('1980')
    const [comboDisabled, setComboDisabled] = useState(false)
    const [profDisabled, setProfDisabled] = useState(false)
    const [isCheckedProfession, setIsCheckedProfession] = useState(false)
    const [isCheckedBackground, setIsCheckedBackground] = useState(false)
    const [isCheckedCarrier, setIsCheckedCarrier] = useState('none')
    const [template, setTemplate] = useState('None')
    const [active, setActive] = useState(0)
    const [clicked, setClicked] = useState(false)
    const [confirmed, setConfirmed] = useState(false)

    const handleToggleProfession = () => {
        if (isCheckedProfession === true) {
            setData((prev) => ({ ...prev, 'profession': '職業不明' }));
        }
        else {
            setData((prev) => ({ ...prev, 'profession': 'エンジニア' }));
            if (template !== 'None') document.getElementById('background').textContent = templateA['エンジニア']
        }
        setIsCheckedProfession(!isCheckedProfession)
    }
    const handleToggleCarrier = () => {
        setIsCheckedCarrier(isCheckedCarrier === 'none' ? 'block' : 'none')
    }

    const handleToggleBackground = () => {
        if (isCheckedBackground === true) {
            setData((prev) => ({ ...prev, 'background': '' }))
            setClicked(false)
        }
        setTemplate('None')
        setIsCheckedBackground(!isCheckedBackground)
    }
    const generatePersonalInfo = async () => {
        setBtnTitle('生成中...')
        try {
            const response = await axios.post(`https://backend.sales-master-ai.net/v1/generateinfo/`,
                {
                    'token': localStorage.getItem('token'),
                    'role': role,
                    'relation': data['relation'],
                    'profession': isCheckedProfession === true ? data['profession'] : '職業不明',
                    'carrier': isCheckedCarrier !== 'none' ? carrier : '',
                    'fees': isCheckedCarrier !== 'none' ? fees : '',
                }
            );

            
            if (response.status === 200) {
                const temp = data['background']
                setData({})
                const resData = response['data'].trim().replace('[', '{').replace(']', '}')
                const startindex = resData.indexOf('{')
                const endindex = resData.indexOf('}')
                const json = JSON.parse(resData.slice(startindex, endindex + 1))
                console.log(json)
                const backgroundArray = json['background'].trim().split('。').filter(Boolean)
                if(backgroundArray[backgroundArray.length - 1].includes('携帯キャリア') && isCheckedCarrier === 'none') backgroundArray.pop()
                setData(JSON.parse(resData.slice(startindex, endindex + 1)))
                setData((prev) => ({ ...prev, 'background': backgroundArray.join('。')}))
                if (template !== 'None' && temp !== '') {
                    await setData((prev) => ({ ...prev, 'background': temp }))
                }
                setConfirmed(true)
                setBtnTitle('営業相手を作る')
            }
        } catch (error) {
            setBtnTitle('営業相手を作る')
        }
    }

    const formatDate = (date) => {
        return date.toISOString().slice(0, 19).replace('T', ' ');
    };

    const now = formatDate(new Date());

    const confirmPersonalInfo = async (e) => {
        const uuid = uuidv4()
        if (confirmed === true) {
            try {
                const response = await axios.post(`https://backend.sales-master-ai.net/v1/confirminfo/`, {
                    ...data, "uuid": uuid, "type": role, 'active': "active", 'recent_time': now, 'level': level
                },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `token ${localStorage.getItem('token')}`,
                        },
                    }
                );
                if (response.status === 200) {
                    toast.success('成功！')
                    navigate(`/chat/${uuid}`, { state: { name: data['name'], disabled: 'アクティブ' } })
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    const handleChange = (e) => {
        setData((prev) => ({ ...prev, [e.target.id]: e.target.value }))
        if (e.target.id === 'relation') {
            setComboDisabled(e.target.value === '' ? false : true)
        }
        if (e.target.id === 'profession') {
            setProfDisabled(e.target.value === '' ? false : true)
            if (e.target.value !== '') setData((prev) => ({ ...prev, 'background': '' }))
            if (e.target.value === '' && template !== 'None') setData((prev) => ({ ...prev, 'background': templateA[profession] }))
        }
    }

    const handleBackground = (index) => {
        setData((prev) => ({ ...prev, 'background': templateA[parseInt(index)][profession] }))
        setActive(parseInt(index))
        setTemplate(index)
        setClicked(true)
    }

    const handleHover = (index) => {
        setData((prev) => ({ ...prev, 'background': templateA[parseInt(index)][profession] }))
        document.getElementById('background').style.opacity = 0.5
    }
    const handleHoverLeave = (index) => {
        if (clicked === true) {
            setData((prev) => ({ ...prev, 'background': templateA[parseInt(index)][profession] }))
            document.getElementById('background').style.opacity = 1

        }
        else document.getElementById('background').textContent = ''
    }

    const handleProfession = (e) => {
        setProfession(e.target.value)
        setData((prev) => ({ ...prev, 'profession': e.target.value }))
        if (template !== 'None') setData((prev) => ({ ...prev, 'background': templateA[e.target.value] }))

    }

    const handleRelation = (e) => {
        setRelation(e.target.value)
        setData((prev) => ({ ...prev, 'relation': e.target.value }))
    }

    const handleLevel = (e) => {
        setLevel(e.target.value)
    }
    const handleCarrier = (e) => {
        setCarrier(e.target.value)
    }

    const handleFees = (e) => {
        setFees(e.target.value)
    }

    return (<>
        <ToastContainer />
        <div className='textbox'>
            <div className='flex-box' style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <form id='generate-form'>
                    <FormRadio />
                    <FormInput handleChange={handleChange} type="text" name="名前" title="name" value={data["name"]} />
                    <FormInput handleChange={handleChange} type="number" name="年齢" title="age" value={data["age"]} />
                    <FormInput handleChange={handleChange} type="text" name="性別" title="gender" value={data["gender"]} />
                    <div className='form-group'>
                        <label style={{ width: '150px' }}>相手との関係性:</label>
                        <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                            <div>
                                <select style={{ height: '30px' }} onChange={handleRelation} disabled={comboDisabled}>
                                    {
                                        relationship.map((item, index) => (
                                            <option value={item}>
                                                {item}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <input type='text' id='relation' name='relation' onChange={handleChange} style={{ width: '50%' }} placeholder='好きな関係性をこちらに入力いただけます'></input>
                    </div>
                    {role === 'toB' ? <FormInput handleChange={handleChange} type="text" name="役職" title="post" value={data["post"]} /> : null}
                    {role === 'toB' ? <FormInput handleChange={handleChange} type="text" name="会社規模" title="company" value={data["company"]} /> : null}
                    {role === 'toB' ? <FormInput handleChange={handleChange} type="text" name="事業内容" title="business" value={data["business"]} /> : null}
                    <div className='form-group'>
                        <label style={{ width: '150px' }}>職業:</label>
                        <ToggleSwitch handleToggle={handleToggleProfession} label='Profession' />
                        {
                            isCheckedProfession === true ? <>
                                <div>
                                    <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                                        <div>
                                            <select style={{ height: '30px' }} onChange={handleProfession} disabled={profDisabled}>
                                                {
                                                    professionList.map((item, index) => (
                                                        <option value={item}>
                                                            {item}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <input type='text' id='profession' name='profession' onChange={handleChange} style={{ width: '40%' }} placeholder='好きな職業をこちらに入力いただけます'></input></> :
                                <input type='text' id='profession' name='profession' value='職業不明' style={{ width: '40%' }}></input>
                        }
                    </div>
                    <FormInput handleChange={handleChange} type="text" name="年収" title="earning" value={data["earning"]} />
                    <FormInput handleChange={handleChange} type="text" name="口調" title="tone" value={data["tone"]} />
                    <FormInput handleChange={handleChange} handleLevel={handleLevel} type="text" name="性格" title="personality" value={data["personality"]} />
                    <div className='form-group'>
                        <label style={{ width: '150px' }}>携帯キャリア:</label>
                        <ToggleSwitch handleToggle={handleToggleCarrier} label='Carrier' />
                        <div style={{ display: isCheckedCarrier }}>
                            <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                                <div>
                                    <select style={{ height: '30px' }} onChange={handleCarrier}>
                                        {
                                            carrierList.map((item, index) => (
                                                <option value={item}>
                                                    {item}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: isCheckedCarrier }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                            <input type='text' required onChange={handleFees} style={{ width: '20%' }}></input>
                            <label>円</label>
                            </div>
                        </div>
                    </div>
                    <FormTextArea handleChange={handleChange} handleTemplate={handleChange} type="text" name="背景" title="background" value={data["background"]} />
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <ToggleSwitch handleToggle={handleToggleBackground} label='Background' />
                        {
                            isCheckedBackground === true ? <><TemplateButton index="0" class_name={`${active === 0 ? "status-button-active" : ""}`} label="A" handleClick={handleBackground} handleHover={handleHover} handleHoverLeave={handleHoverLeave} />
                                {/* <TemplateButton index="1" class_name={`${active === 1 ? "status-button-active" : ""}`} label="B" handleClick={handleBackground} handleHover={handleHover} handleHoverLeave={handleHoverLeave}/>
                            <TemplateButton index="2" class_name={`${active === 2 ? "status-button-active" : ""}`} label="C" handleClick={handleBackground} handleHover={handleHover} handleHoverLeave={handleHoverLeave}/>
                            <TemplateButton index="3" class_name={`${active === 3 ? "status-button-active" : ""}`} label="D" handleClick={handleBackground} handleHover={handleHover} handleHoverLeave={handleHoverLeave}/>
                            <TemplateButton index="4" class_name={`${active === 4 ? "status-button-active" : ""}`} label="E" handleClick={handleBackground} handleHover={handleHover} handleHoverLeave={handleHoverLeave}/> */}</> : null
                        }
                    </div>
                    <div className='form-group'>
                        <div className='generate-button' id='generate-button' onClick={generatePersonalInfo}>
                            {btnTitle}
                        </div>
                    </div>
                    <div className='form-group'>
                        <div id='confirm-button' onClick={confirmPersonalInfo}>
                            営業を始める
                        </div>
                    </div>
                </form>
            </div>
        </div></>

    )
}
