import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios';
import { FaCloudUploadAlt } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { setUserData } from '../reducers/userSlice';
import { CiCircleCheck } from "react-icons/ci";

export default function Settings() {

    const dispatch = useDispatch()
    const { user } = useSelector(state => state.user)

    const fileInputRef = useRef(null)
    const [image, setImage] = useState(null)


    const [name, setName] = useState('');
    const [meme, setMeme] = useState('');
    const [base64image, setBase64Image] = useState('none');
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        setMeme(localStorage.getItem('meme'))
        setName(localStorage.getItem('username'))
        setBase64Image(localStorage.getItem('avatar'))
    }, [])

    const handleButtonClick = (e) => {
        e.preventDefault()
        fileInputRef.current.click()
    }

    // const handleSubmit = async (e) => {
    //     e.preventDefault()
    //     try {
    //         const response = await axios.post(`https://backend.sales-master-ai.net/api/account/`, {
    //             meme: meme,
    //             userId: name,
    //             avatar: base64image,
    //             token: localStorage.getItem('token')
    //         },
    //         );
    //         if (response.status === 200) {
    //             dispatch(setUserData({ user_name: name, userMeme: meme, userAvatar: base64image }))
    //             localStorage.setItem('meme', response['data']['meme'])
    //             localStorage.setItem('avatar', response['data']['avatar'])
    //             localStorage.setItem('username', response['data']['username'])
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }

    // }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const url = localStorage.getItem('admin') === 'manager' ? 'https://backend.sales-master-ai.net/api/manager/' : 'https://backend.sales-master-ai.net/api/account/' 
        try {
            const response = await axios.post(url, 
                localStorage.getItem('admin') === 'manager' ? {
                    meme: meme,
                    userId: name,
                    avatar: base64image,
                    prevId: localStorage.getItem('username')
                }:
                {
                    meme: meme,
                    userId: name,
                    avatar: base64image,
                    token: localStorage.getItem('token')
                }
            );
            if (response.status === 200) {
                dispatch(setUserData({ user_name: name, userMeme: meme, userAvatar: base64image }))
                localStorage.setItem('meme', response['data']['meme'])
                localStorage.setItem('avatar', response['data']['avatar'])
                localStorage.setItem('username', response['data']['username'])
            }
        } catch (error) {
            console.log(error)
        }

    }

    const handleFile = async (e) => {
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = () => {
            setBase64Image(reader.result)
        };
        reader.onerror = (error) => {
            console.error(error)
        }
    }

    return (
        <div style={{display:'flex', justifyContent:'center'}}>
            <div className='textbox' style={{ width: '50%' }}>
                <form>
                    <div>
                        <h3>現在の画像</h3>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div className={`textbox-image ${isHovered ? 'hovered' : ''}`} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                                <input type='file' style={{ display: 'none' }} ref={fileInputRef} onChange={handleFile} />
                                {base64image !== "none" ? <img src={base64image} className={`${isHovered ? 'animate' : ''}`} style={{ width: '100px', height: '100px', borderRadius: '50%', transition: 'all 0.3s ease' }} alt='ユーザー画像' onClick={handleButtonClick} /> : <FaCloudUploadAlt fontSize='30px' color='#5E845D' onClick={handleButtonClick} />}
                            </div>
                        </div>
                    </div>
                    <div>
                        <label for="name">名前:</label>
                        <input type="text" id='name' name='name' value={name} onChange={(e) => setName(e.target.value)} required></input>
                    </div>
                    <div>
                        <label for="description">一言:</label>
                        <input type="text" id='description' name='description' value={meme} onChange={(e) => setMeme(e.target.value)}></input>
                    </div>
                    <button onClick={handleSubmit} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>更新</button>
                </form>
            </div>
        </div>
    )
}
