import React from 'react'


export default function ManagerTableComp(props) {

    return (
        <tr>
            <td onClick={() => props.handleClick(props.id)}>{props.name}</td>
            <td>{props.id}</td>
            <td>{props.password}</td>
            <td>{props.registerDate}</td>
            <td>{props.request_token}</td>
            <td>{props.usage}</td>
            <td>{props.stop}</td>
            <td style={{ display: 'flex', justifyContent: 'space-around' }}>
                <button onClick={() => props.handleDelete(props.id)}>削除</button>
            </td>
        </tr>
    )
}
