import React from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

export default function OtherMessageItem(props) {

    const navigate = useNavigate()

    const handleChat = () => {
        navigate(`/chat/${props.uuid}`, {state: {name: props.name, disabled: props.active}})
    }

    const handleFeedback = () => {
        navigate(`/feedback/${props.uuid}`, {state: {name: props.name, age:props.age, userId: localStorage.getItem('detailId')}})
    }

    return (
        <div className='message-item' style={{ display: 'block' }}>
            <div className='message-item-header'>
                <p><strong>{props.name}({props.age})</strong></p>
            </div>
            <div className='message-item-body'>
                <p>{props.post} / {props.profession}</p>
            </div>
            <div className='message-item-subtext'>
                <div>
                    <p>ステータス: {props.active}</p>
                    <p>最終更新: {props.time} / 顧客タイプ: {props.type}</p>
                </div>
                <div className='message-item-subtext-button'>
                    <button className='message-item-subtext-button-feedback' style={{display: props.display1}} onClick={handleFeedback}>
                        詳細へ
                    </button>
                    <button className='message-item-subtext-button-detail' style={{display: props.display2}} onClick={handleChat}>
                        チャットへ
                    </button>
                </div>
            </div>
        </div>
    )
}
