import React from 'react'

export default function FormTextArea(props) {
    const templateList = ['A']
    return (
        <div className='form-group'>
            <label for={props.title} style={{ width: '150px' }}>{props.name}:</label>
            {/* <div>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                    <div>
                        <select style={{ height: '30px' }} onChange={props.handleTemplate} disabled={props.disabled} id='templateCombo'>
                            <option value='None'>-</option>
                            {
                                templateList.map((item, index) => (
                                    <option value={item} key={index}>
                                        {item}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                </div>
            </div> */}
            <textarea type={props.type} id={props.title} name={props.title} rows={17} required onChange={props.handleChange} value={props.value} disabled={props.disabled} style={{ width: '80%' }}></textarea>
            <div style={{ flex: 0.5 }}></div>
        </div>
    )
}
