import React, { useEffect, useState } from 'react'
import MessageItem from '../component/MessageItem'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import OtherMessageItem from '../component/OtherMessageItem'
import {v4 as uuidv4} from 'uuid'


export default function OtherChat() {

    const [data, setData] = useState([])
    const [modify, setModify] = useState(false)
    const navigate = useNavigate()
    const params = useParams()
    const getInfo = async () => {
        try {
            const response = await axios.get(`https://backend.sales-master-ai.net/v1/confirminfo/?detailId=${params.other_id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `token ${localStorage.getItem('token')}`,
                }
            },
            );
            if (response.status === 200) {
                setData(response['data'])
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleDelete = async (uuid) => {
        try {
            const response = await axios.get(`https://backend.sales-master-ai.net/v1/confirminfo/?uuid=${uuid}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `token ${localStorage.getItem('token')}`,
                }
            },
            );
            if (response.status === 200) {
                modify === false ? setModify(true) : setModify(false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleShare = async (uuid) => {
        const share_link = uuidv4()
        const pageUrl = `https://sales-master-ai.net/import/${share_link}`
        try {
            const response = await axios.post(`https://backend.sales-master-ai.net/v1/confirminfo/`,{
                uuid: uuid,
                share_link: share_link 
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `token ${localStorage.getItem('token')}`,
                }
            },
            );
            if (response.status === 200) {
            }
        } catch (error) {
            console.log(error)
        }
        navigate(`/share/${uuid}`,{state: {url:pageUrl}})
    }

    useEffect(() => {
        getInfo()
    }, [modify])

    return (
        <div className='flex-box' style={{ flexDirection: 'column' }}>
            <ToastContainer/>
            <div>
                <div className='message-grid' id='messages'>
                    {
                        data.map((item, index) => {
                            return item['active'] === 'feedback' ? <OtherMessageItem key={index} uuid={item['uuid']} profession={item['profession']} disabled = {item['active'] === 'feedback' ? false : true} post={item['post']} type={item['type'] === 'toB' ? '企業' : item['type'] === 'toC' ? '個人' : 'テレアポ'} name={item['name']} age={item['age']} active={item['active'] === 'active' ? 'アクティブ' : item['active'] === 'feedback' ? 'フィードバック済み' : 'アーカイブ'} display2={item['active'] === 'feedback' ? 'none' : 'block'} display1={item['active'] === 'feedback' ? 'block' : 'none'} time={item['recent_time'].substring(0, 19)}/>: null
                        })
                    }
                </div>
            </div>
        </div>
    )
}
