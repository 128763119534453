import React, { useEffect } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { FaAngleRight, FaHome, FaListUl } from "react-icons/fa";
import { FaUserGroup, FaPen } from "react-icons/fa6"
import { IoSettingsSharp, IoLogOutOutline } from "react-icons/io5"
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setClickedState } from '../reducers/userSlice'
export default function Sidebar() {

    const navigate = useNavigate()
    const { user } = useSelector(state => state.user)
    const dispatch = useDispatch()
    const location = useLocation()
    const params = useParams()

    let detailId = "none"

    if (location.state) {
        if (location.state.userId) detailId = location.state.userId
    }
    let detailParmas = params.other_id ? params.other_id : "none"
    localStorage.setItem('detailId', detailId)



    const sidebarList = [
        {
            icon: <FaHome fontSize='20px' />,
            label: "ホーム",
            link: "/dashboard"
        },
        {
            icon: <FaPen fontSize='20px' />,
            label: "営業先AI作成",
            link: "/chat_new"
        },
        {
            icon: <FaListUl fontSize='20px' />,
            label: "営業AI一覧",
            link: "/chat"
        },
        {
            icon: <IoSettingsSharp fontSize='20px' />,
            label: "プロフィール設定",
            link: "/settings"
        },
        {
            icon: <FaUserGroup fontSize='20px' />,
            label: "グループ",
            link: "/group"
        },
        {
            icon: <IoLogOutOutline fontSize='20px' />,
            label: "ログアウト",
            link: "/login"
        }
    ]

    const managerSidebarList = [
        {
            icon: <FaHome fontSize='20px' />,
            label: "ホーム",
            link: "/dashboard"
        },
        {
            icon: <FaListUl fontSize='20px' />,
            label: "ユーザー一覧",
            link: "/users"
        },
        {
            icon: <FaUserGroup fontSize='20px' />,
            label: "グループ一覧",
            link: "/groups"
        },
        {
            icon: <IoSettingsSharp fontSize='20px' />,
            label: "プロフィール設定",
            link: "/settings"
        },
        {
            icon: <IoLogOutOutline fontSize='20px' />,
            label: "ログアウト",
            link: "/login"
        }
    ]

    const adminSidebarList = [
        {
            icon: <FaHome fontSize='20px' />,
            label: "ホーム",
            link: "/dashboard"
        },
        {
            icon: <FaListUl fontSize='20px' />,
            label: "マネージャー一覧",
            link: "/users"
        },
        {
            icon: <IoLogOutOutline fontSize='20px' />,
            label: "ログアウト",
            link: "/login"
        }
    ]

    const detailSidebarList = [
        {
            icon: <FaHome fontSize='20px' />,
            label: "ホーム",
            link: "/dashboard"
        },
        {
            icon: <FaListUl fontSize='20px' />,
            label: "営業AI一覧",
            link: `/chatOther/${localStorage.getItem('detailId')}`
        },
    ]


    const toggleMenuClose = (label, link) => {
        dispatch(setClickedState({ clickedState: label }))
        localStorage.setItem('clickedState', label);
        localStorage.setItem('currentLocation', link)
        const menu = document.getElementById('menu');
        menu.classList.remove('active');
        if(label === "営業先AI作成") navigate(`${link}`)
        else navigate(`${link}`, { state: { userId: localStorage.getItem('detailId') } })
    }

    const toggleMenuXClose = () => {
        const menu = document.getElementById('menu');
        menu.classList.remove('active');
        navigate(`${localStorage.getItem('currentLocation')}`, { state: { userId: localStorage.getItem('detailId') } })
    }

    const logOut = async () => {
        localStorage.clear();
        toast.success('ログアウトしました')
        navigate("/login");
    }

    return (
        <div className="menu" id="menu">
            <button className="close-button" id="close-button" onClick={() => toggleMenuXClose()} style={{ fontFamily: "'Noto Sans JP', sans-serif;" }}>
                ×
            </button>
            <div>
                <h2>セールス達人AI</h2>
                <div className='sidebar-avatar'>
                    <div style={{ width: '90px', height: '90px', border: '2px solid rgba(94,132, 93, 0.6)', borderRadius: '50px', marginTop: '20px' }}>
                        <img src={localStorage.getItem('avatar') !== 'none' ? localStorage.getItem('avatar') : 'https://placehold.jp/3d4070/ffffff/150x150.png?text=%E6%9C%AA%E8%A8%AD%E5%AE%9A'} height='80px' width='80px' alt='avatar' style={{ margin: '5px', borderRadius: '50px' }} />
                    </div>
                    <p style={{ color: '#8C8C8C', marginBottom: '25px' }}>{localStorage.getItem('username')}さん</p>
                </div>
                {
                    detailId !== "none" || detailParmas !== "none" ? <ul>
                        {
                            detailSidebarList.map((item, index) => (
                                <li key={index} onClick={() => toggleMenuClose(item.label, item.link)}>
                                    {item.icon}
                                    <div>{item.label}</div>
                                    <FaAngleRight />
                                </li>
                            ))
                        }
                    </ul> :
                        localStorage.getItem('admin') === "user" ? <ul>
                            {
                                sidebarList.map((item, index) => (
                                        <li key={index} onClick={item.label !== "ログアウト" ? () => toggleMenuClose(item.label, item.link) : logOut}>
                                            {item.icon}
                                            <div>{item.label}</div>
                                            <FaAngleRight />

                                        </li>
                                ))
                            }
                        </ul> : localStorage.getItem('admin') === 'manager' ?
                            <ul>
                                {
                                    managerSidebarList.map((item, index) => (
                                            <li key={index} onClick={item.label !== "ログアウト" ? () => toggleMenuClose(item.label, item.link) : logOut}>
                                                {item.icon}
                                                <div>{item.label}</div>
                                                <FaAngleRight />
                                            </li>
                                    ))
                                }
                            </ul> :
                            <ul>
                                {
                                    adminSidebarList.map((item, index) => (
                                            <li key={index} onClick={item.label !== "ログアウト" ? () => toggleMenuClose(item.label, item.link) : logOut}>
                                                {item.icon}
                                                <div>{item.label}</div>
                                                <FaAngleRight />
                                            </li>
                                    ))
                                }
                            </ul>
                }
            </div>
        </div>
    )
}
