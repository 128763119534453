import React, { useEffect } from "react";
import "../main/dashboard.css";
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "../component/Sidebar";
import Navbar from "../component/Navbar";
import { FaLeaf } from "react-icons/fa";

const Dashboard = () => {

    const navigate = useNavigate()

    useEffect(() => {
        if(localStorage.getItem('username') === null) navigate('/login')
    },[])
    return (
        <div className="dashboard-container">
            <Sidebar />
            <div className="screen">
                <Navbar/>
                <div className="content">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default Dashboard;