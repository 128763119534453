import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import AddTable from '../component/AddTable'

export default function EditGroup() {

    const params = useParams()
    const group_id = params.group_id

    const [addId, setAddId] = useState('')
    const [users, setUsers] = useState([])
    const [modify, setModify] = useState(false)

    const getUser = async () => {
        try {
            const response = await axios.get(`https://backend.sales-master-ai.net/v1/group/edit/?group_id=${group_id}`, {
            },
            );
            if (response.status === 200) {
                setUsers(response['data']['users'])
                setUsers(prevArray => prevArray.filter((_, index) => index !== 0));
            }
        } catch (error) {
            console.log(error)
        }
    }

    const postUser = async () => {
        let response = {}
        try {
            response = await axios.post(`https://backend.sales-master-ai.net/v1/group/post/`, {
                'add_id': addId,
                group_id: group_id,
                manager_id: localStorage.getItem('username')
            }
            );
            if (response.status === 200) {
                console.log(response)
                if (response['data']['error'] === 'Cannot add manager') toast.warn('マネージャーアカウントは追加できません')
                else {
                    if (response['data']['error'] === 'There is no account with that id') toast.warn('正しいIDを入力してください。')
                    else {
                        if (response['data']['error'] === 'You do not have this user account') toast.warn('該当するユーザーアカウントがありません。')
                        else toast.success('成功！')
                    }
                }
                setModify(!modify)
            }
        } catch (error) {
            console.log(response)
        }
    }

    const handleClick = (e) => {
        e.preventDefault()
        postUser()
    }

    useEffect(() => {
        getUser()
    }, [modify])

    const handleDelete = async (id) => {
        try {
            const response = await axios.delete(`https://backend.sales-master-ai.net/v1/group/delete/?group_id=${group_id}&&delete_id=${id}`, {
            },
            );
            if (!response['data']['error']) {
                toast.success('成功！')
                setModify(!modify)
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div style={{ display: 'block' }}>
            <ToastContainer />
            <div className='textbox'>
                <form>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <label>メンバーID追加:</label>
                        <input type='text' name='member_id' style={{ margin: '5px' }} required onChange={(e) => setAddId(e.target.value)}></input>
                        <button onClick={handleClick}>追加</button>
                    </div>
                </form>
                <h3>メンバー一覧</h3>
                <table>
                    <thead>
                        <tr>
                            <th>名前</th>
                            <th>操作</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            users.map((item, index) => {
                                return <AddTable id={item} key={index} handleDelete={handleDelete} />
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}
