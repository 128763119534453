import React from 'react'
import { Link } from 'react-router-dom'

export default function RankComp(props) {

    return (
        <div className='ranking-item'>
            <div className='ranking-position'></div>
            <div className='ranking-details'>
                <div className='ranking-name'>{props.name}</div>
                <div className='ranking-score'>{props.score}/25点</div>
            </div>
            <div className='ranking-feedback'>
                <Link to={props.url}>フィードバックを見る</Link>
            </div>
        </div>
    )
}