import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useParams } from 'react-router-dom';
import UserTableComp from '../component/UserTableComp';
import { ToastContainer, toast } from 'react-toastify';

export default function DetailUsers() {

    const [users, setUsers] = useState([])
    const [modify, setModify] = useState(false)

    const params = useParams()

    const getUserInfo = async (e) => {
        try {
            const response = await axios.get(`https://backend.sales-master-ai.net/api/users?manager=${params.manager_id}`, {
            },
            );
            if (response.status === 200) {
                const userData = await response.data
                setUsers(userData['data'])
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getUserInfo();
    }, [modify])

    return (
        <div className='textbox'>
            <ToastContainer />
            <table>
                <thead>
                    <tr>
                        <th>名前</th>
                        <th>ID</th>
                        <th>パスワード</th>
                        <th>登録日</th>
                        <th>消費トークン数</th>
                        <th>トークン数上限</th>
                        <th>プラン</th>
                        <th>状態</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        users.map((item, index) => {
                            return <UserTableComp key={index} name={item['username']} id={item['userId']} request={item['request']} tokens={item['tokens']} availableTokens={item['available_tokens']} plan={item['plan']} password={item['password']} registerDate={item['created_at'].toString().slice(0,19).replace('T',' ')} status={item['status']} />
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}
