import React, { useEffect, useState } from 'react'
import ProfileItem from '../component/ProfileItem'
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

export default function DetailGroup() {

    const params = useParams()
    const [data, setData] = useState([])
    const [series, setSeries] = useState([])
    const [avatar, setAvatar] = useState([])
    const [meme, setMeme] = useState([])
    const navigate = useNavigate()

    const feedbackOptions = {
        chart: {
            type: 'line'
        },
        title: {
            text: '直近の点数',
            align: 'left'
        },
        xAxis: {
            title: {
                text: ''
            },
            tickInterval: 1,
            min: 0.5,
        },
        yAxis: {
            title: {
                text: '点数',
            },
            min: 0,
        },
        tooltip: {
            pointFormat: '<span style="color:{point.color};font-size:20px;">\u25CF</span> {series.name}: <b>{point.y}</b>'
        },
        series: series
    };

    const getGroupMember = async () => {
        try {
            const response = await axios.get(`https://backend.sales-master-ai.net/v1/group/member/?groupId=${params.manager_id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `token ${localStorage.getItem('token')}`,
                }
            },
            );
            if (response.status === 200) {
                const total = response['data']['data']
                setSeries([])
                setAvatar([])
                for (let i = 0; i < total.length; i++) {
                    let temp = {}
                    setData([])
                    for (let k = 0; k < total[i]['data'].length; k++) {
                        if (total[i]['data'][k]['active_mark'] !== "") {
                            const temp = total[i]['data'][k]['active_mark'].match(/\d+/g).map(Number)[0] + total[i]['data'][k]['active_mark'].match(/\d+/g).map(Number)[1] + total[i]['data'][k]['active_mark'].match(/\d+/g).map(Number)[2] + total[i]['data'][k]['active_mark'].match(/\d+/g).map(Number)[3] + total[i]['data'][k]['active_mark'].match(/\d+/g).map(Number)[4]
                            setData(prevArray => [...prevArray, temp])
                        }
                    }
                    temp = {
                        name: total[i]['user'],
                        pointStart: 1,
                        data: data
                    }
                    setSeries(prevArray => [...prevArray, temp])
                    setAvatar(prevArray => [...prevArray, total[i]['avatar']])
                    setMeme(prevArray => [...prevArray, total[i]['meme']])
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleClick = (userId) => {
        navigate(`/dashboard`, {state: {userId: userId}})
    }

    useEffect(() => {
        getGroupMember()
    }, [])

    return (
        <div className='textbox'>
            <h2>{params.manager_id} メンバー</h2>
            <div id="feedbacks" className="tabcontent" style={{ display: "block" }}>
                <div>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={feedbackOptions}
                    />
                </div>
            </div>
            <div className='profile-container'>
                {
                    series.map((item, index) => (
                        <ProfileItem src={avatar[index] !== 'none' ? avatar[index] : `https://placehold.jp/3d4070/ffffff/150x150.png?text=%E6%9C%AA%E8%A8%AD%E5%AE%9A`} name={item['name']} meme={meme[index]} handleClick={handleClick}/>
                    ))
                }
            </div>
        </div>
    )
}