import React from 'react'

export default function TemplateButton(props) {

    return (
        <button type='button' className={`status-button ${props.class_name}`} id={props.index} onMouseEnter={() =>props.handleHover(props.index)} onMouseLeave={() =>props.handleHoverLeave(props.index)} onMouseDown={() =>props.handleClick(props.index)}>
            {props.label}
        </button>
    )
}
