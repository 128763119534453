import React, { useEffect, useState } from 'react'
import axios from 'axios';
import TableComp from '../component/TableComp'
import { useNavigate } from 'react-router-dom';
import ManagerTableComp from '../component/ManagerTableComp';
import { toast, ToastContainer } from 'react-toastify';

export default function Users() {

    const [users, setUsers] = useState([])
    const [info, setInfo] = useState({})
    const [token, setToken] = useState({})
    const [availableToken, setAvailableToken] = useState({})
    const [modify, setModify] = useState(false)

    const navigate = useNavigate()
    const getUserInfo = async (e) => {
        try {
            const response = await axios.get(`https://backend.sales-master-ai.net/api/users?manager=${localStorage.getItem('username')}`, {
            },
            );
            if (response.status === 200) {
                const userData = await response.data['data']
                setUsers(userData)
                for (let i = 0; i < userData.length; i++) {
                    setInfo((prev) => ({ ...prev, [userData[i]['userId']]: userData[i]['password'] }))
                    setToken((prev) => ({ ...prev, [userData[i]['userId']]: userData[i]['tokens'] }))
                    setAvailableToken((prev) => ({ ...prev, [userData[i]['userId']]: userData[i]['available_tokens'] }))
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getManagerInfo = async (e) => {
        try {
            const response = await axios.get(`https://backend.sales-master-ai.net/api/manager/`, {
            },
            );
            if (response.status === 200) {
                setUsers(response['data'])
            }
        } catch (error) {
            console.log(error)
        }
    }

    const moveToUsers = (managerId) => {
        navigate('/users/' + managerId)
    }

    const formatDate = (date) => {
        return date.toString().slice(0, 19).replace('T', ' ');
    };

    useEffect(() => {
        localStorage.getItem('admin') === 'admin' ? getManagerInfo() : getUserInfo()
    }, [modify])

    const handleChange = (e) => {
        e.preventDefault()
        setInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }))
    }

    const handleClick = async (value, id) => {
        try {
            const response = await axios.get(`https://backend.sales-master-ai.net/api/account/info/?password=${value}&&username=${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `token ${localStorage.getItem('token')}`,
                }
            },
            );
            if (response.status === 200) {
                toast.success('成功！')
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleDelete = async (id) => {
        const userConfirmed = window.confirm("本当にアカウントを削除してよろしいでしょうか？");
        if (userConfirmed) {
            try {
                const response = await axios.delete(`https://backend.sales-master-ai.net/api/account/del/?userId=${id}&&managerId=${localStorage.getItem('username')}`, {
                },
                );
                if (response.status === 200) {                    
                    setModify(!modify)
                }
            } catch (error) {
                console.log(error)
            }
        }

    }

    const handleDeleteManager = async (id) => {
        try {
            const response = await axios.delete(`https://backend.sales-master-ai.net/api/manager/del/?managerId=${id}`, {
            },
            );
            if (response.status === 200) {
                setModify(!modify)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const createUser = () => {
        navigate('/create_user')
    }
    const handleDetail = async (userId, value) => {
        const response = await axios.post(`https://backend.sales-master-ai.net/api/login/`, {
            userId: userId,
            password: value
        },
        );
        if (response.status === 200) {
            localStorage.setItem("token", response['data']['token'])
            navigate(`/dashboard`, { state: { userId: userId } })
        }
    }

    const AddToken = async (request, id) => {
        try {
            const response = await axios.get(`https://backend.sales-master-ai.net/api/account/info/?request=${request}&&userId=${id}&&managerId=${localStorage.getItem('username')}`, {},
            );
            if (response.status === 200) {
                
                setModify(!modify)
            }
        } catch (error) {
            toast.error('Error')
        }
    }

    return (
        <>
            <ToastContainer />
            <div className='textbox'>
                {
                    localStorage.getItem('admin') !== 'admin' ?
                        <table>
                            <thead>
                                <tr>
                                    <th>名前</th>
                                    <th>ID</th>
                                    <th>パスワード</th>
                                    <th>グループ</th>
                                    <th>消費トークン数</th>
                                    <th>トークン数上限</th>
                                    <th>プラン</th>
                                    <th>操作</th>
                                    <th>状態</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    users.map((item, index) => {
                                        return <TableComp key={index} name={item['username']} group={item['groups'].slice(5)} id={item['userId']} value={info[item['userId']]} token={token[item['userId']].toLocaleString('en-US')} availableToken={availableToken[item['userId']].toLocaleString('en-US')} plan={item['plan']} request={item['request']} status={item['status']} handleChange={handleChange} handleClick={handleClick} handleDelete={handleDelete} handleDetail={handleDetail} AddToken={AddToken}/>
                                    })
                                }
                            </tbody>
                        </table> :
                        <table>
                            <thead>
                                <tr>
                                    <th>名前</th>
                                    <th>ID</th>
                                    <th>パスワード</th>
                                    <th>登録日</th>
                                    <th>トークン追加回収</th>
                                    <th>使用中</th>
                                    <th>使用中止</th>
                                    <th>操作</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    users.map((item, index) => {
                                        return <ManagerTableComp name={item['name']} id={item['managerId']} password={item['password']} registerDate={formatDate(item['created_at'])} usage={item['during_use']} stop={item['stop_use']} request_token={item['request_token']} handleClick={moveToUsers} handleDelete={handleDeleteManager} />
                                    })
                                }
                            </tbody>
                        </table>
                }
                <div className='form-group' style={{ display: 'block', textAlign: 'center' }}>
                    <div id='create_button' onClick={createUser}>
                        {localStorage.getItem('admin') === 'admin' ? 'マネージャー作成' : 'ユーザー作成'}
                    </div>
                </div>

            </div>
        </>
    )
}
