import React, { useState } from 'react'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function CreateUser() {

    const [name, setName] = useState('')
    const [id, setId] = useState('')
    const [plan, setPlan] = useState('エントリー')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [isVisible, setIsVisible] = useState("password");
    const [isConfirmVisible, setIsConfirmVisible] = useState("password");

    const navigate = useNavigate()

    let title = localStorage.getItem('admin') === 'admin' ? 'マネージャー' : 'ユーザー'

    const togglePasswordVisibility = (id) => {
        if (id === 1) setIsVisible(isVisible === "password" ? "text" : "password");
        else setIsConfirmVisible(isConfirmVisible === "password" ? "text" : "password");
    }

    const handleClick = (e) => {
        e.preventDefault()
        localStorage.getItem('admin') === 'admin' ? createManager() : createUser()
    }

    const createManager = async () => {
        if (password === confirmPassword) {
            try {
                const response = await axios.post(`https://backend.sales-master-ai.net/api/manager/`, {
                    name: name,
                    managerId: id,
                    password: password
                },
                );
                if (response.status === 200) {
                    navigate('/users');
                }
            } catch (error) {
                toast.warn('このIDを持つ管理者アカウントが既に存在します。')
                document.getElementById('user_name').value = ''
                document.getElementById('user_id').value = ''
                document.getElementById('password').value = ''
                document.getElementById('confirm-password').value = ''
            }
        }
        else {
            toast.warn('パスワードを確認してください')
        }

    }

    const createUser = async () => {
        if (password === confirmPassword) {
            try {
                const response = await axios.post(`https://backend.sales-master-ai.net/api/register/`, {
                    username: name,
                    userId: id,
                    password: password,
                    request: 'register',
                    plan: plan,
                    supervisor: localStorage.getItem('username')
                },
                );
                if (response.status === 200) {
                    navigate('/users');
                }
            } catch (error) {
                toast.warn('このIDを持つ管理者アカウントが既に存在します。')
                document.getElementById('user_name').value = ''
                document.getElementById('user_id').value = ''
                document.getElementById('password').value = ''
                document.getElementById('confirm-password').value = ''

            }
        }
        else {
            toast.warn('パスワードを確認してください')
        }

    }

    return (
        <div style={{ display: 'block' }}>
            <ToastContainer />
            <div className='textbox'>
                <h2 style={{ textAlign: 'center' }}>{title}新規作成</h2>
                <form id='group_form' onSubmit={handleClick}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'right' }}>
                        <label id='name' style={{ width: '150px' }}>{title}名:</label>
                        <input type='text' id='user_name' name='user_name' required onChange={(e) => setName(e.target.value.trim())} style={{ paddingRight: '2rem' }} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'right' }}>
                        <label id='id' style={{ width: '150px' }}>{title}ID:</label>
                        <input type='text' id='user_id' name='user_id' required onChange={(e) => setId(e.target.value)} style={{ paddingRight: '2rem' }} />
                    </div>
                    {
                        localStorage.getItem('admin') !== 'admin' ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'right' }}>
                            <label id='id' style={{ width: '150px' }}>プラン選択:</label>
                            <div id='group-select-container' style={{width:'213px'}}>
                                <select style={{ height: '40px', borderRadius: '5px' }} onChange={(e) => setPlan(e.target.value)}>
                                    <option value="エントリー">
                                        エントリー(100万トークン)
                                    </option>
                                    <option value="スタンダード">
                                        スタンダード(200万トークン)
                                    </option>
                                    <option value="プレミアム">
                                        プレミアム(400万トークン)
                                    </option>
                                </select>
                            </div>
                        </div> : null
                    }
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'right' }}>
                        <label id='label_password' style={{ width: '150px' }}>パスワード:</label>
                        <div style={{ position: 'relative' }}>
                            <input type={isVisible} id='password' name='password' required onChange={(e) => setPassword(e.target.value)} style={{ paddingRight: '2rem' }} />
                            <span onClick={() => togglePasswordVisibility(1)} className='span-eye'>
                                <svg stroke="currentColor" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ color: '#9ca3af', width: '1rem', height: '1rem' }}>
                                    <path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" stroke-width="2" stroke-linejoin="round" stroke-linecap="round"></path>
                                    <path d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" stroke-width="2" stroke-linejoin="round" stroke-linecap="round"></path>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'right' }}>
                        <label id='label_confirm-password' style={{ width: '150px' }}>パスワード再入力:</label>
                        <div style={{ position: 'relative' }}>
                            <input type={isConfirmVisible} id='confirm-password' name='password' required onChange={(e) => setConfirmPassword(e.target.value)} style={{ paddingRight: '2rem' }} />
                            <span onClick={() => togglePasswordVisibility(2)} className='span-eye'>
                                <svg stroke="currentColor" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ color: '#9ca3af', width: '1rem', height: '1rem' }}>
                                    <path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" stroke-width="2" stroke-linejoin="round" stroke-linecap="round"></path>
                                    <path d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" stroke-width="2" stroke-linejoin="round" stroke-linecap="round"></path>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <button id='create_button'>{title}作成</button>
                </form>
            </div>
        </div>
    )
}