import React from 'react'


export default function TableComp(props) {

    // const url = import.meta.env.BASE_URL

    return (
        <tr>
            <td onClick={() => props.handleDetail(props.id, props.value)}>{props.name}</td>
            <td>{props.id}</td>
            <td>
                <input type='text' id={props.id} value={props.value} onChange={props.handleChange}></input>
            </td>
            <td>
                {props.group}
            </td>
            <td>{props.token}</td>
            <td>{props.availableToken}</td>
            <td>{props.plan}</td>
            <td style={{ display: 'flex', justifyContent: 'space-around' }}>
                <button onClick={() => props.handleClick(props.value, props.id)} style={{ marginRight: '10px' }}>適応</button>
                <button onClick={() => props.handleDelete(props.id)} style={{backgroundColor:'red'}}>削除</button>
            </td>
            <td>{
                props.request === 'Add' ?
                    <button className='tab-button' style={{ backgroundColor: '#EDA450', padding: '0px' }} onClick={() => props.AddToken(props.request, props.id)}>トークン要求</button> :
                    props.request === 'Delete' ?
                        <button className='tab-button' style={{ backgroundColor: '#D94E5C', padding: '0px' }}>削除要求</button> :
                        props.status === 'use' ? <button className='tab-button' style={{ backgroundColor: '#3BA574', padding: '0px' }}>使用中</button> :
                            props.status === 'stop' ?
                                <button className='tab-button' style={{ backgroundColor: '#808080', padding: '0px' }}>使用停止</button>
                                : null
            }
            </td>
        </tr>
    )
}
