import React from 'react'

export default function AddTable(props) {

    return (
        <tr>
            <td>{props.id}</td>
            <td>
                <button onClick={() => props.handleDelete(props.id)} style={{maxWidth:'100px'}}>削除</button>
            </td>
        </tr>
    )
}